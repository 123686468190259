<template>
	<div v-if="displayTags && displayTags.length" class="tags m-lg:mb-4" :class="{'shimmer-parent': showShimmer}">
		<IndexTag v-for="tag in displayTags" :key="'tags_' + tag.urlname" :tag="tag" />
	</div>
</template>

<script setup>
const { displayTags, displayTagsStatus } = useTags();

const showShimmer = ref(false);

watch(displayTagsStatus, (val) => {
	setTimeout(() => {
		showShimmer.value = !val
	}, 50);
});
</script>

<style lang="scss">
.tags {
  min-height: calc(5 * 1.8em + 5 * 0.5em + .4em);
  align-content: flex-start;
}
</style>